<template>
  <div>
    <basic-container>
    <avue-crud :option="tableOption"
                 :data="tableData"
                 :table-loading="tableLoading"
                 :page="page"
                 ref="crud"
                 @current-change="currentChange"
                 @size-change="sizeChange"
                 @selection-change="selectionChange"
                 @refresh-change="refreshChange"
                 @row-save="handleSave"
                 @row-update="handleUpdate"
                 @row-del="handleDel">
            <template slot="status" slot-scope="scope">
                <el-switch v-model="scope.row.status" :inactive-value="1" :active-value="0"
                @change="change(scope.row)">
                </el-switch>
            </template>
        <template slot="menuLeft">
          <importBtn
              :outApi="this.proApis.PROCESSENERGYWARNINGIEXPORT"
              :innerAPI="this.proApis.PROCESSENERGYWARNINGIMPORT"
              :API="this.proApis.PROCESSENERGYWARNINGIMPORTTEMPLATE"
              @refresh="refreshData"
          ></importBtn>
        </template>
        <template slot="menuRight">
            <el-select clearable v-model="departmentId" :placeholder="$t('BELONGING WORKSHOP')" size="small" style="width:100px !important;marginRight: 5px;">
                <el-option v-for="value in workList" :label="value.name" :value="value.id" :key="value.id"></el-option>
            </el-select>
        </template>
        <!-- <template slot="menu">
            <el-button type="text" size="mini" icon="el-icon-discover" style="color:red;">{{$t('DISABLE')}}</el-button>
        </template> -->
    </avue-crud>
    </basic-container>
  </div>
</template>
<script>
import {list,add,edit,remove} from "@/api/warn"
import importBtn from "@/components/importExport/importBtn";
    import { calssList, getMechinaAll, getMachineCodeAll, departmentListSelect} from "@/api/config";
export default {
  components:{
    importBtn
  },
    data() {
        return {
            tableLoading: false,
            workList: [],
            wklnList: [],
            nameOrIdLike: "",
            departmentId: "",
            page: {
                pageSize: 20,
                pagerCount: 5,
                total: 10,
                pageCurrent: 1
            },
            disabled: true,
            tableData: [],
            tableOption: {
                delBtn: true,
                menuWidth: 200,
                selection: true,
                tip: false,
                labelWidth: 150,
                columnBtn: false,
                dialogFullscreen: true,
                addBtnText: this.$t('ADD'),
                delBtnText: this.$t('DELETE'),
                editBtnText: this.$t('Edit'),
                emptyText: this.$t('No Data'),
                menuTitle: this.$t('OPERATION'),
                column: [{
                    label: this.$t('EQUIPMENT NAME'),
                    // hide: true,
                    type: "tree",
                    display:false,
                    props: {
                        label: "name",
                        value: "id"
                    },
                    dicData: [],
                    prop: "machineId",
                    rules: [{
                        required: true,
                        message: this.$t('PLEASE ENTER THE DEVICE NAME'),
                        trigger: "blur"
                    }],
                }, {
                    label: this.$t('MODEL CODE'),
                    type: "tree",
                    hide: true,
                    prop: "machineModelId",
                    props: {
                        label: "code",
                        value: "id"
                    },
                    dicData: [],
                    rules: [{
                        required: true,
                        message: this.$t('PLEASE ENTER THE MODEL CODE'),
                        trigger: "blur"
                    }],
                }, {
                    label: this.$t('ORDER CODE'),
                    prop: "machineName",
                    hide:true,
                    display: false,
                    rules: [{
                        required: true,
                        message: this.$t('PLEASE ENTER THE MODEL NAME'),
                        trigger: "blur"
                    }],
                }, {
                    label: this.$t('MODEL CODE'),
                    editDisplay: false,
                    display: false,
                    prop: "modelCode",
                    rules: [{
                        required: true,
                        message: this.$t('PLEASE ENTER THE MODEL CODE'),
                        trigger: "blur"
                    }],
                }, {
                    label: this.$t('BELONGING WORKSHOP'),
                    type: "select",
                    dicData: [],
                    props: {
                        label: "name",
                        value: "id"
                    },
                    width: 130,
                    prop: "departmentId",
                    formatter: function (row) {
                        return row.departmentName;
                    }
                }, {
                    label: this.$t('BELONGING WORKING PROCEDURE'),
                    dicUrl: this.businessUrl + "process/selectAll",
                    hide: true,
                    type: "select",
                    prop: "processId",
                    rules: [{
                        required: true,
                        message: this.$t('PLEASE ENTER WORKSHOP'),
                        trigger: "blur"
                    }],
                    props: {
                        label: "name",
                        value: "id"
                    }
                }, {
                    label: this.$t('BELONGING WORKING PROCEDURE'),
                    dicUrl: this.businessUrl + "process/selectAll",
                    display: false,
                    type: "select",
                    prop: "processName",
                    props: {
                        label: "name",
                        value: "id"
                    }
                }, {
                    label: this.$t('UPPER CURRENT LIMIT'),
                    value: 0,
                    type: "number",
                    prop: "currentMax"
                }, {
                    label: this.$t('MINIMUM VOLTAGE'),
                    value: 0,
                    type: "number",
                    prop: "voltageMin"
                }, {
                    label: this.$t('MAXIMUM VOLTAGE'),
                    value: 0,
                    type: "number",
                    prop: "voltageMax"
                }, {
                    label: this.$t('MAXIMUM POWER FACTOR'),
                    value: 0,
                    type: "number",
                    width: 130,
                    prop: "powerFactorMax"
                }, {
                    label: this.$t('MINIMUM POWER FACTOR'),
                    value: 0,
                    width: 130,
                    type: "number",
                    prop: "powerFactorMin"
                }, {
                    label: this.$t('CREATOR'),
                    display: false,
                    prop: "createUserName"
                }, {
                    label: this.$t('CREATION TIME'),
                    addDisplay: false,
                    editDisplay: false,
                    prop: "createTime"
                }, {
                    label: this.$t('MODIFICATION TIME'),
                    addDisplay: false,
                    editDisplay: false,
                    prop: "updateTime"
                }]
            }
        }
    },
    created() {
        calssList().then(res => {
        this.tableOption.column[4].dicData=res.data.data;
        this.workList=res.data.data;
      }).then(()=>{
        this.list();
      });
      getMachineCodeAll().then(res => {
        this.tableOption.column[1].dicData = res.data;
      });
      getMechinaAll().then(res => {
        this.tableOption.column[0].dicData = res.data.data;
      });
    },
    methods: {
        currentChange(pageCurrent) {
          this.page.pageCurrent = pageCurrent;
          this.list();
        },
        sizeChange(pageSize) {
          this.page.pageCurrent = 1;
          this.page.pageSize = pageSize;
          this.list();
        },
        refreshData() {
          this.list();
        },
        handleDel(row) {
            this.$confirm(this.$t('IsDel'), this.$t('Tips'), {
                confirmButtonText: this.$t('Yes'),
                cancelButtonText: this.$t('Cancel'),
                type: 'warning'
            }).then(() => {
                remove(row.id).then(() => {
                    this.list();
                })
            })
        },
        handleUpdate(row,index,done) {
            edit(row).then(() => {
                this.list();
                done();
            })
        },
        handleSave(row,done) {
            add(row).then(() => {
                this.list();
                done();
            })
        },
        change(row) {
            changeStatus(row)
        },
        selectionChange(list) {
            console.log(list);
            list != "" ? this.disabled = false : this.disabled = true;
        },
        refreshChange() {
          this.list();
        },
        list(){
          this.tableData = [];
          list(this.page.pageCurrent,this.page.pageSize,this.departmentId).then(res => {
              this.page.total = res.data.data.total;
              this.tableData = res.data.data.items;
              this.tableData.forEach(v => {
                  v.status = v.status;
              })
          })
        },
        envText: function() {
            return this.env
        }
  }
}
</script>
<style scoped>
    .el-select-dropdown .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
        padding-left: 10px;
    }
</style>